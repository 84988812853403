import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { list, reset, onClick } from '../../actions/company/list';
import { logout } from '../../actions/auth/logout';
import '../../assets/styles/company/list.css';

class CompanyList extends Component {
  static propTypes = {
    retrieved: PropTypes.array,
    loading: PropTypes.any.isRequired,
    companyId: PropTypes.number,
    error: PropTypes.string,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.reset();
    this.props.list();
  }

  render() {
    if (this.props.retrieved && this.props.retrieved.length === 1) {
      this.props.onClick(this.props.retrieved[0]['id']);
    }

    return (
      <div>
        <div className="header">
          <div className="pull-right">
            <button class="LogoutButton btn btn-primary btn-sm" onClick={this.props.logout}>Log Out</button>
          </div>
          <h1>Registered Importers</h1>
        </div>

        {this.props.loading && (
          <div className="alert alert-info">Loading...</div>
        )}

        {this.props.error && (
          <div className="alert alert-danger">{this.props.error}</div>
        )}

        {this.props.companyId && (
          <Redirect to='/' />
        )}

        <table className="importers-table table table-responsive table-hover">
          <tbody>
            {this.props.retrieved &&
              this.props.retrieved.map(item => (
                <tr key={item['id']} onClick={() => this.props.onClick(item['id'])}>
                  <td>{item['name']}</td>
                  <td>
                    <span>&gt;</span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    retrieved,
    loading,
    error,
    companyId
  } = state.company.list;
  return { retrieved, loading, error, companyId };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list()),
  onClick: event => dispatch(onClick(event)),
  reset: eventSource => dispatch(reset(eventSource)),
  logout: eventSource => dispatch(logout(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyList);
