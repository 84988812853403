import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'VEHICLE_ERROR', error };
}

export function uploaded(uploaded) {
  return { type: 'VEHICLE_UPLOADED', uploaded };
}

export function loading(loading) {
  return { type: 'VEHICLE_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'VEHICLE_SUCCESS', retrieved };
}

export function data(data) {
  return { type: 'VEHICLE_DATA', data };
}

export function retrieve(companyId, vehicleId) {
  return dispatch => {
    dispatch(loading(true));

    return fetch('/api/company/'+companyId+'/vehicle/'+vehicleId).then(response => {
      dispatch(loading(false));
      return response.json();
    }).then(response => {
      dispatch(success(!response.error));
      dispatch(data(response));
      dispatch(error(null));
    }).catch(e => {
      dispatch(loading(false));
      dispatch(error(e.message));
    });
  };
}

function toBase64(files) {
  let promises = files.map(file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve({
        filename: file.name,
        content: reader.result.split(',')[1],
        phototype: file.type
      });
      reader.onerror = error => reject(error);
    })
  });

  return Promise.all(promises);
}

function attemptUpload(cid, vid, file_b64, dispatch, retried=0) {
  return new Promise((resolve, reject) => {
    return fetch('/api/company/'+cid+'/vehicle/'+vid+'/photos', {
      method: 'POST',
      body: JSON.stringify(file_b64)
    }).then(res => {
      resolve(res.json());
    }).catch( e => {
      console.log(e)
      dispatch(error(`${e.message} ... Upload failed...retrying in 5 seconds...`));
      if (retried < 5) {
        setTimeout(() => {
          dispatch(error(''))
          attemptUpload(cid, vid, file_b64, dispatch, retried + 1).then(resolve, reject);
        }, 5000);
      } else {
        reject('Max retries exceeded')
      }
    });
  });
}

function progressUpdate(total, completed, failures) {
  if (failures) {
    return `Uploading [${completed} / ${total}]...\r\nFailed: ${failures}`;
  }

  return `Uploading [${completed} / ${total}]...`
}

function uploadComplete(total, completed) {
  return `Successfully uploaded: ${completed} / ${total} photos...`
}

export function uploadPhoto(cid, vid, e) {
  return async (dispatch) => {
    dispatch(uploaded(null))

    const files = Array.from(e.target.files);
    let completed = 0;
    let failures = 0;

    dispatch(loading(progressUpdate(files.length, completed, failures)));

    const fileHandler = (file) => {
      return (resolve, _) => {
        toBase64([file]).then((result) => {
          attemptUpload(cid, vid, result, dispatch).then(() => {
            completed++;
            dispatch(loading(progressUpdate(files.length, completed, failures)));

            if (completed + failures === files.length) {
              dispatch(uploaded({
                message: uploadComplete(files.length, completed),
                success: failures === 0,
              }))
              setTimeout(() => {
                dispatch(uploaded(null))
              }, 5000);
              dispatch(retrieve(cid, vid));
            }
            resolve(true)
          }).catch(e => {
            console.log(e)
            failures += 1;
            dispatch(loading(progressUpdate(files.length, completed, failures)));
            dispatch(error('Max retries exceeded...'));
            if (completed + failures === files.length) {
              dispatch(uploaded({
                message: uploadComplete(files.length, completed),
                success: failures === 0,
              }))
              dispatch(retrieve(cid, vid));
            }

            // Just resolve and move onto the next file
            resolve(false)
          });
        }).catch(e => {
          dispatch(loading(false));
          dispatch(error(e.message));

          // Just resolve and move onto the next file
          resolve(false)
        });
      }
    };
    for (let file of files) {
      await new Promise(fileHandler(file));
    }
  };
}

export function reset() {
  return dispatch => {
    dispatch(error(null));
    dispatch(loading(false));
  };
}
