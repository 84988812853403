import { fetch } from "../../utils/dataAccess";

export function searchText(value) {
  return { type: "SEARCH_TEXT", value };
}

export function error(error) {
  return { type: "SEARCH_LIST_ERROR", error };
}

export function loading(loading) {
  return { type: "SEARCH_LIST_LOADING", loading };
}

export function success(retrieved) {
  return { type: "SEARCH_LIST_SUCCESS", success: retrieved };
}

export function shipmentResults(results) {
  return { type: "SEARCH_SHIPMENT_RESULTS", results };
}

export function vehicleResults(results) {
  return { type: "SEARCH_VEHICLE_RESULTS", results };
}

export function setSearchText(text) {
  return dispatch => {
    dispatch(searchText(text));
  };
}

export function query(company_id, text) {
  return dispatch => {
    if (text.length === 0) {
      dispatch(reset());
      return;
    }

    dispatch(loading(true));
    fetch("/api/company/" + company_id + "/search/" + text)
      .then(response => {
        dispatch(loading(false));
        return response.json();
      })
      .then(response => {
        dispatch(success(!response.error));

        if (response.shipments.count > 0) {
          dispatch(shipmentResults(response.shipments.data));
        } else {
          dispatch(shipmentResults(null));
        }

        if (response.vehicles.count > 0) {
          dispatch(vehicleResults(response.vehicles.data));
        } else {
          dispatch(vehicleResults(null));
        }
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));
    dispatch(shipmentResults(null));
    dispatch(vehicleResults(null));
    dispatch(success(null));
  };
}
