import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { retrieve, reset, uploadPhoto } from '../../actions/vehicle/details';

import FooterBar from '../FooterBar';

import './VehicleDetails.css';

import '../../assets/styles/vehicle/show.css';
import '../../assets/styles/search/searchlist.css';

import caricon from '../../assets/images/car-icon.png';
import upload from '../../assets/images/upload.png';
import imagesred from '../../assets/images/images-red.png';
import imagesgreen from '../../assets/images/images-green.png';

class VehicleDetails extends Component {
  static propTypes = {
    uploaded: PropTypes.object,
    loading: PropTypes.any.isRequired,
    error: PropTypes.string,
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.retrieve(this.props.companyId, this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const vehicle = this.props.data;
    const shipment = (vehicle && vehicle.shipment) ? vehicle.shipment : null;

    if (this.props.scanner.enabled) {
      return (<Redirect to='/' />);
    }

    let loadingMessage = 'Loading...';
    if (this.props.loading !== true) {
      loadingMessage = this.props.loading;
    }

    let uploadedClass = 'alert alert-danger';
    if (this.props.uploaded && this.props.uploaded.success) {
      uploadedClass = 'alert alert-info';
    }

    return (
      <div>
        <div className="show-header">
            <span className="show-backlink">
            <Link to="../..">
              Back
            </Link>
            </span>
            <span className="show-title">{vehicle && (vehicle.vin)}</span>
        </div>

        <div className="shipment-topper">
          <img className="shipment-icon" src={caricon} alt="Shipment"/>
        </div>

        {this.props.uploaded && (
          <div className={uploadedClass} role="status">
            {this.props.uploaded.message}
          </div>
        )}

        {this.props.loading && (
          <div className="alert alert-info" role="status">
            {loadingMessage}
          </div>
        )}


        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}

        {!this.props.loading && (
          <div className="shipment-details">
            <span className="heading">Vehicle Details</span>
            <p>{vehicle && (vehicle.year + ' ' + vehicle.make + ' ' + vehicle.model)}<br/>
              <span className="data-title">{vehicle && (vehicle.vin)}</span>
            </p>
            <span className="divider"></span>

            {vehicle && vehicle.photos.length === 0 && (
              <p><span className="data-title">Image Gallery: </span><span className="no-images"><img src={imagesred} alt="No Images"/> No Images Present</span></p>
            )}

            {vehicle && vehicle.photos.length > 0 && (
              <p><span className="data-title">Image Gallery: </span><span className="has-images"><img src={imagesgreen} alt="No Images"/> {vehicle.photos.length} Images Present</span></p>
            )}

            {vehicle && vehicle.photos.length > 0 && (
              <div className="image-gallery">
                {vehicle && vehicle.photos.map((photo, i) => (<div key={'photo-'+i} className="gallery-image-wrapper"><img className="gallery-image" src={photo} alt={'Vehicle Image '+i} /></div>))}
              </div>
            )}

            <input id="multi-file" name="multi-file" type="file" accept="image/*" multiple onChange={e => this.props.uploadPhoto(this.props.companyId, vehicle.id, e)}/>
            <label className="button" htmlFor="multi-file"><img src={upload} alt="Upload"/>Upload Photos</label>

          </div>
        )}

        {!this.props.loading && (
          <div id="cardlist-wrapper">
            {shipment && (
              <div className="shipment-details">
                <span className="heading">Shipment Details</span>
                <p>
                  <span className="data-title">Entry Number: </span>{shipment && (shipment['entry_number'])}<br/>
                  <span className="data-title">PAPS #: </span>{shipment && (shipment['paps_number'])}
                </p>
                <p>
                  <span className="data-title">Status: </span>{shipment && (shipment['status'])}<br/>
                  <span className="data-title">Entry Date </span>{shipment && (shipment['entry_date'])}
                </p>
                <p>
                  <span className="data-title">Origin: </span>{shipment && (shipment['origin'])}<br/>
                  <span className="data-title">Destination: </span>{shipment && (shipment['destination'])}<br/>
                  <span className="data-title">Carrier: </span>{shipment && (shipment['carrier'])}<br/>
                  <span className="data-title">Port: </span>{shipment && (shipment['port'])}
                </p>
              </div>
            )}

          </div>
        )}

        <FooterBar />

      </div>
    );
  }
}

const mapStateToProps = state => ({
  companyId: state.company.list.companyId,
  error: state.vehicle.details.error,
  loading: state.vehicle.details.loading,
  uploaded: state.vehicle.details.uploaded,
  data: state.vehicle.details.data,
  scanner: state.search.scanner,
});

const mapDispatchToProps = dispatch => ({
  retrieve: (cid, sid) => dispatch(retrieve(cid, sid)),
  reset: eventSource => dispatch(reset(eventSource)),
  uploadPhoto: (cid, vid, es) => dispatch(uploadPhoto(cid, vid, es))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleDetails);
