import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import store from './utils/store.js';
import history from './utils/history.js';

import PrivateRoute from './components/PrivateRoute'

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import * as serviceWorker from './serviceWorker';

// Import your reducers and routes here

import { Login } from './components/auth/index.js';
import { CompanyList } from './components/company/index.js';
import { ShipmentDetails } from './components/shipment/index.js';
import { VehicleDetails } from './components/vehicle/index.js';

import SearchList from './components/search/SearchList';

import './assets/styles/global.css';

import { BarcodeReader } from "dynamsoft-javascript-barcode";
BarcodeReader.license = 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxOTU2NDExLVRYbFhaV0pRY205cVgyUmljZyIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21sdHMuZHluYW1zb2Z0LmNvbSIsIm9yZ2FuaXphdGlvbklEIjoiMTAxOTU2NDExIiwiY2hlY2tDb2RlIjoxODg2MjQzMDkxfQ==';
BarcodeReader.engineResourcePath = "https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.6.20/dist/";

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/login" exact component={Login} key="login"/>
        <PrivateRoute exact path="/companies" component={CompanyList} key="list" />
        <PrivateRoute strict exact path="/" component={SearchList} />
        <Route path="/shipments/:id" component={ShipmentDetails} exact key="show" />,
        <Route path="/vehicles/:id" component={VehicleDetails} exact key="show" />
        <Route render={() => <h1>Not Found</h1>} />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
