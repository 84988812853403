import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { onChange, onSubmit, reset } from "../../actions/auth/login";

import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  FormCheck
} from "react-bootstrap";

import HeroImage from "../../assets/images/HeroImage.png";
import BayLogo from "../../assets/images/BayLogo_Color.png";
import Error from "../common/Error";
import Loading from "../common/Loading";

import "../../assets/styles/login.css";

class Login extends Component {
  static propTypes = {
    error: PropTypes.string,
    loading: PropTypes.any.isRequired,
    reset: PropTypes.func.isRequired,
    success: PropTypes.bool
  };

  componentWillUnmount() {
    this.props.reset();
  }

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      rememberMe: true
    };
  }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleCheckboxChange = event => {
    this.setState({
      [event.target.id]: event.target.checked
    });
  };

  render() {
    if (this.props.success) {
      this.props.reset();
      return <Redirect to="/" />;
    }

    return (
      <div className="Login">
        <div
          className="HeroImageHeader"
          style={{ backgroundImage: "url(" + HeroImage + ")" }}
        ></div>
        <img className="BayLogo" src={BayLogo} alt="Bay Brokerage" />
        <span className="Importer-Login">Importer Login</span>
        <span className="TIERS-RIC">TIERS Registered Importer Companion</span>

        {this.props.loading && <Loading />}

        {!this.props.loading && (
          <form onSubmit={this.props.onSubmit} onChange={this.props.onChange}>
            <FormGroup className="FormGroup" controlId="username">
              <FormLabel className="FormLabel">Username</FormLabel>
              <FormControl
                autoFocus
                value={this.state.username}
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup className="FormGroup" controlId="password">
              <FormLabel className="FormLabel">Password</FormLabel>
              <FormControl
                value={this.state.password}
                onChange={this.handleChange}
                type="password"
              />
            </FormGroup>
            <p align="center">
              <a
                href="https://baybrokerageus.com/resources/paps-status/"
                className="btn paps-status-btn pavanam paps-btn"
              >
                PAPS Status
              </a>
            </p>

            <FormGroup className="FormGroupRemember" controlId="rememberMe">
              <FormCheck
                className="RememberMe"
                type="checkbox"
                label="Remember Me"
                onChange={this.handleCheckboxChange}
              />
            </FormGroup>
            {this.props.error && <Error>{this.props.error}</Error>}
            <Button
              className="SubmitButton"
              block
              disabled={!this.validateForm()}
              type="submit"
            >
              LOGIN
            </Button>
          </form>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { error, loading, success, submitDisabled } = state.auth.login;
  return { error, loading, success, submitDisabled };
};

const mapDispatchToProps = dispatch => ({
  onChange: event => dispatch(onChange(event)),
  onSubmit: event => dispatch(onSubmit(event)),
  reset: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
