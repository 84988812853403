import { combineReducers } from 'redux';

export function error(state = null, action) {
  switch (action.type) {
    case 'COMPANY_LIST_ERROR':
      return action.error;

    case 'COMPANY_LIST_MERCURE_DELETED':
      return `${action.retrieved['@id']} has been deleted by another user.`;

    case 'COMPANY_LIST_RESET':
      return null;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'COMPANY_LIST_LOADING':
      return action.loading;

    case 'COMPANY_LIST_RESET':
      return false;

    default:
      return state;
  }
}

export function success(state = null, action) {
  switch (action.type) {
    case 'COMPANY_LIST_SUCCESS':
      return action.success;

    default:
      return state;
  }
}

export function companyId(state = null, action) {
  switch (action.type) {
    case 'COMPANY_LIST_ID':
      return action.companyId;

    default:
      return state;
  }
}

export function retrieved(state = null, action) {
  switch (action.type) {
    case 'COMPANY_LIST':
      return action.retrieved;
    case 'COMPANY_LIST_RESET':
      return [];
    default:
      return state;
  }
}

export function eventSource(state = null, action) {
  switch (action.type) {
    case 'COMPANY_LIST_MERCURE_OPEN':
      return action.eventSource;

    case 'COMPANY_LIST_RESET':
      return null;

    default:
      return state;
  }
}

export default combineReducers({ error, loading, success, companyId, retrieved, eventSource });
