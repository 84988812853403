import { fetch } from '../../utils/dataAccess';
import { clearCompanyId } from '../company/list';

export function error(error) {
  return { type: 'LOGIN_AUTH_ERROR', error };
}

export function loading(loading) {
  return { type: 'LOGIN_AUTH_LOADING', loading };
}

export function success(authenticated) {
  return { type: 'LOGIN_AUTHENTICATED', success: authenticated };
}

export function token(value) {
  return { type: 'LOGIN_TOKEN', token: value };
}

export function expiration(expiration) {
  return { type: 'LOGIN_EXPIRATION', expiration };
}

export function userId(id) {
  return { type: 'LOGIN_USER_ID', id };
}

export function remember(remember) {
  return { type: 'LOGIN_REMEMBER', remember };
}

export function onChange(e) {
  return dispatch => {

  };
}

export function onSubmit(event) {
  event.preventDefault();

  const username = event.target['username'].value;
  const password = event.target['password'].value;
  const rememberVal = event.target['rememberMe'].checked === true;

  return dispatch => {
    dispatch(remember(rememberVal));
    dispatch(loading(true));

    return fetch('/api/login', {
      method: 'POST',
      body: JSON.stringify({
        username: username,
        password: password
      })
    }).then(response => {
      dispatch(loading(false));
      return response.json();
    }).then(retrieved => {

      dispatch(token(retrieved.token));
      dispatch(expiration(retrieved.expiration));
      dispatch(userId(retrieved.user_id));

      dispatch(clearCompanyId());
      dispatch(success(!retrieved.error));
    }).catch(e => {
      dispatch(loading(false));
      if (e.message === 'Unauthorized') {
        dispatch(error('Invalid username or password.'))
      } else {
        dispatch(error('An error occurred! Please try again.'))
      }
    });
  };
}

export function reset() {
  return dispatch => {
    dispatch(success(null));
    dispatch(loading(false));
    dispatch(error(null));
  };
}
