import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { retrieve, reset } from '../../actions/shipment/details';

import FooterBar from '../FooterBar';

import '../../assets/styles/shipment/show.css';
import '../../assets/styles/search/searchlist.css';

import shipmenticon from '../../assets/images/shipment-icon.png';
import caricon from '../../assets/images/car-icon.png';

class ShipmentDetails extends Component {
  static propTypes = {
    loading: PropTypes.any.isRequired,
    error: PropTypes.string,
    retrieve: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.retrieve(this.props.companyId, this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const shipment = this.props.data;

    if (this.props.scanner.enabled) {
      return (<Redirect to='/' />);
    }

    return (
      <div>
        <div className="show-header">
          <span className="show-backlink">
            <Link to="..">
              Back
            </Link>
          </span>
          <span className="show-title">{shipment && (shipment['entry_number'])}</span>
        </div>

        <div className="shipment-topper">
          <img className="shipment-icon" src={shipmenticon} alt="Shipment"/>
        </div>

        <div className="shipment-details">
          <span className="heading">Shipment Details</span>
          <p>
            <span className="data-title">Entry Number: </span>{shipment && (shipment['entry_number'])}<br/>
            <span className="data-title">PAPS #: </span>{shipment && (shipment['paps_number'])}
          </p>
          <p>
            <span className="data-title">Status: </span>{shipment && (shipment['status'])}<br/>
            <span className="data-title">Entry Date </span>{shipment && (shipment['entry_date'])}
          </p>
          <p>
            <span className="data-title">Origin: </span>{shipment && (shipment['origin'])}<br/>
            <span className="data-title">Destination: </span>{shipment && (shipment['destination'])}<br/>
            <span className="data-title">Carrier: </span>{shipment && (shipment['carrier'])}<br/>
            <span className="data-title">Port: </span>{shipment && (shipment['port'])}
          </p>
        </div>

        <div id="cardlist-wrapper">
          {shipment && shipment.vehicles && (
            <div>
              <span className="cardlist-result-title">{shipment.vehicles.length} Vehicle Results</span>
              <ul className="cardlist">
                {shipment.vehicles && shipment.vehicles.map(vehicle => (
                  <li key={'vehicle-'+vehicle['id']}>
                    <a href={'/vehicles/'+vehicle['id']}>
                      <div className="carditem">
                        <img className="cardimage" src={caricon} alt="cardimage" />
                        <div className="cardcontent">
                          <span className="cardtitle">{vehicle['make'] + ' ' + vehicle['model'] + ' ' + vehicle['year']}</span>
                          <span className="cardsubtitle">{vehicle['vin']}</span>
                          <span className="cardlinktext">{vehicle['create_date']}</span>
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {this.props.loading && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        {this.props.error && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.error}
          </div>
        )}

        <FooterBar />

      </div>
    );
  }

}

const mapStateToProps = state => ({
  companyId: state.company.list.companyId,
  error: state.shipment.details.error,
  loading: state.shipment.details.loading,
  data: state.shipment.details.data,
  scanner: state.search.scanner
});

const mapDispatchToProps = dispatch => ({
  retrieve: (cid, sid) => dispatch(retrieve(cid, sid)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShipmentDetails);
