import { combineReducers } from 'redux';

export function error(state = null, action) {
  switch (action.type) {
    case 'VEHICLE_ERROR':
      return action.error;
    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'VEHICLE_LOADING':
      return action.loading;
    default:
      return state;
  }
}

export function retrieved(state = null, action) {
  switch (action.type) {
    case 'VEHICLE_SUCCESS':
      return action.retrieved;
    default:
      return state;
  }
}

export function uploaded(state = null, action) {
  switch (action.type) {
    case 'VEHICLE_UPLOADED':
      return action.uploaded;
    default:
      return state;
  }
}

export function data(state = null, action) {
  switch (action.type) {
    case 'VEHICLE_DATA':
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({ error, loading, retrieved, uploaded, data });
