import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'SHIPMENT_ERROR', error };
}

export function loading(loading) {
  return { type: 'SHIPMENT_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'SHIPMENT_SUCCESS', retrieved };
}

export function data(data) {
  return { type: 'SHIPMENT_DATA', data };
}

export function retrieve(companyId, shipmentId) {
  return dispatch => {
    dispatch(loading(true));
    return fetch('/api/company/'+companyId+'/shipment/'+shipmentId).then(response => {
      dispatch(loading(false));
      return response.json();
    }).then(response => {
      dispatch(success(!response.error));
      dispatch(data(response));
    }).catch(e => {
      dispatch(loading(false));
      dispatch(error(e.message));
    });
  };
}

export function reset() {
  return dispatch => {
    dispatch(error(null));
    dispatch(loading(false));
    dispatch(success(null));
    dispatch(data(null));
  };
}
