/**
 * Created by hperkins on 9/19/2019.
 */

import React from 'react';

export default class Loading extends React.Component {
  render() {
    return (<div className="alert alert-info" role="status">Loading...</div>);
  }
}
