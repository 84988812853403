import React, { Component } from "react";
import { connect } from 'react-redux';
import { enable } from '../actions/search/scanner';

import searchgreen from '../assets/images/search-green.svg';
import camera from '../assets/images/camera.svg';
import '../assets/styles/footerbar.css';

class FooterBar extends Component {
  render() {
        return (
            <div className="footer-bar">
                <div className="footer-bar-inner">
                  <span className="footer-bar-block">
                    <img className="block-icon" src={searchgreen} alt="Search by text"/>
                    <span className="block-content">Text<br/>Search</span>
                  </span>
                  <span  className="footer-bar-block" onClick={() => this.props.enableScanner()}>
                    <img className="block-icon" src={camera} alt="Search with camera"/>
                    <span className="block-content">Barcode<br/>Search</span>
                  </span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
  enableScanner: () => dispatch(enable()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterBar);
