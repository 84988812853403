import { combineReducers } from 'redux';

export function searchText(state = null, action) {
  switch (action.type) {
    case 'SEARCH_TEXT':
      return action.value;
    default:
      return state;
  }
}

export function error(state = null, action) {
  switch (action.type) {
    case 'SEARCH_LIST_ERROR':
      return action.error;
    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'SEARCH_LIST_LOADING':
      return action.loading;
    default:
      return state;
  }
}

export function success(state = null, action) {
  switch (action.type) {
    case 'SEARCH_LIST_SUCCESS':
      return action.success;
    default:
      return state;
  }
}

export function shipmentResults(state = null, action) {
  switch (action.type) {
    case 'SEARCH_SHIPMENT_RESULTS':
      return action.results;
    default:
      return state;
  }
}

export function vehicleResults(state = null, action) {
  switch (action.type) {
    case 'SEARCH_VEHICLE_RESULTS':
      return action.results;
    default:
      return state;
  }
}


export default combineReducers({ error, loading, success, shipmentResults, vehicleResults, searchText });
