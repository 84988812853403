import React, { Component } from "react"; import { connect } from "react-redux"; import { Redirect } from "react-router-dom"; import history from "../../utils/history.js";

import Scanner from "../search/Scanner";
import FooterBar from "../FooterBar";
import { Link } from "react-router-dom";

import { setSearchText, query, reset } from "../../actions/search/searchlist";

import "../../assets/styles/search/searchlist.css";

import shipmenticon from "../../assets/images/shipment-icon.png";
import caricon from "../../assets/images/car-icon.png";

class SearchList extends Component {
  resetAndBack() {
    this.props.reset();
    window.location = '/companies';
  }
  debounce() {
    let inDebounce;
    let company_id = this.props.companyId;
    let query = this.props.query;
    let delay = 1000;
    let setText = this.props.setSearchText;

    return function(e) {
      let value = e.target.value;
      setText(value);
      clearTimeout(inDebounce);
      if (value !== "") {
        inDebounce = setTimeout(() => query(company_id, value), delay);
      } else {
        inDebounce = setTimeout(
          () => query(company_id, "something that we will never find"),
          delay
        );
      }
    };
  }

  render() {
    if (!this.props.companyId) {
      return <Redirect to="/companies" />;
    }

    if (this.props.scanner.enabled) {
      return <Scanner />;
    }

    this.debounce()

    return (
      <div className="search">
        <div className="search-bar">
          <div className="show-header">
            <span className="show-backlink">
            <Link to="#" onClick={() => this.resetAndBack()}>
              Back
            </Link>
            </span>
            <span className="show-title">Search</span>
          </div><br/><br/>
          <input
            id="somethingsucks"
            className="search-field"
            value={this.props.searchText}
            type="text"
            name="search"
            autoComplete="off"
            onChange={this.debounce()}
          />
        </div><br/><br/>
        <div id="cardlist-wrapper">
          {this.props.shipmentResults && (
            <div>
              <span className="cardlist-result-title">
                {this.props.shipmentResults.length} Shipment Results
              </span>
              <ul className="cardlist">
                {this.props.shipmentResults &&
                  this.props.shipmentResults.map(item => (
                    <li
                      key={"shipment-" + item["id"]}
                      onClick={() => history.push("/shipments/" + item["id"])}
                    >
                      <div className="carditem">
                        <img
                          className="cardimage"
                          src={shipmenticon}
                          alt="cardimage"
                        />
                        <div className="cardcontent">
                          <span className="cardtitle">
                            {item["entry_number"]}
                          </span>
                          <span className="cardsubtitle">
                            {item["num_vehicles"]} Vehicles
                          </span>
                          <span className="cardlinktext">
                            {item["entry_date"]}
                          </span>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          )}
          {this.props.vehicleResults && (
            <div>
              <span className="cardlist-result-title">
                {this.props.vehicleResults.length} Vehicle Results
              </span>
              <ul className="cardlist">
                {this.props.vehicleResults &&
                  this.props.vehicleResults.map(item => (
                    <li
                      key={"vehicle-" + item["id"]}
                      onClick={() => history.push("/vehicles/" + item["id"])}
                    >
                      <div className="carditem">
                        <img
                          className="cardimage"
                          src={caricon}
                          alt="cardimage"
                        />
                        <div className="cardcontent">
                          <span className="cardtitle">
                            {item["make"] +
                              " " +
                              item["model"] +
                              " " +
                              item["year"]}
                          </span>
                          <span className="cardsubtitle">{item["vin"]}</span>
                          <span className="cardlinktext">
                            {item["create_date"]}
                          </span>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          )}
          {!(this.props.vehicleResults || this.props.shipmentResults) && (
            <div>
              <span className="search-info-text search-info-top">
                SEARCH FOR A VEHICLE OR SHIPMENT BY TEXT WITH SEARCH BAR ABOVE.
              </span>
              <span className="search-info-text search-info-bottom">
                OR SEARCH A VIN BY SCANNING THE VIN BARCODE, WITH BARCODE
                SEARCH.
              </span>
            </div>
          )}
        </div>
        <FooterBar />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  companyId: state.company.list.companyId,
  searchText: state.search.searchlist.searchText,
  shipmentResults: state.search.searchlist.shipmentResults,
  vehicleResults: state.search.searchlist.vehicleResults,
  scanner: state.search.scanner
});

const mapDispatchToProps = dispatch => ({
  setSearchText: text => dispatch(setSearchText(text)),
  query: (company_id, text) => dispatch(query(company_id, text)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchList);
