/**
 * Created by hperkins on 10/10/2019.
 */

export function setScannerState(enabled) {
  return dispatch => {
    dispatch({type: "SCANNER_ENABLED", enabled})
  }
}

export function enable() {
  return dispatch => {
    dispatch(setScannerState(true))
  }
}

export function disable() {
  return dispatch => {
    dispatch(setScannerState(false))
  }
}

export function reset() {
  return dispatch => {
    dispatch(setScannerState(false))
  }
}
