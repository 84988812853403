import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'COMPANY_LIST_ERROR', error };
}

export function loading(loading) {
  return { type: 'COMPANY_LIST_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'COMPANY_LIST_SUCCESS', success: retrieved };
}

export function retrieved(retrieved) {
  return { type: 'COMPANY_LIST', retrieved };
}

export function companyId(companyId) {
  return { type: 'COMPANY_LIST_ID', companyId };
}

export function clearCompanyId() {
  return dispatch => {
    dispatch(companyId(null));
  }
}

export function setCompanyId(id) {
  return dispatch => {
    dispatch(companyId(id));
  }
}

export function onClick(id) {
  return dispatch => {
    dispatch(companyId(id));
  }
}

export function list(page = '/api/companies') {
  return dispatch => {
    dispatch(loading(true));

    fetch(page).then(response => {
      dispatch(loading(false));
      return response.json();
    }).then(response => {
      dispatch(success(!response.error));
      dispatch(retrieved(response.companies));
    }).catch(e => {
      dispatch(loading(false));
      dispatch(error(e.message));
    });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));
    dispatch(companyId(null));
    dispatch(retrieved(null));
    dispatch(success(null));
  };
}
