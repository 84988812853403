/**
 * Created by hperkins on 9/19/2019.
 */

import React from 'react';

export default class Error extends React.Component {
  render() {
    let style = {
      width: '290px',
      height: '22px',
      margin: 'auto',
      fontFamily: 'OpenSans, sans-serif',
      fontSize: '16px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.38',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#ba2323'
    };

    return (<div style={style}>{this.props.children}</div>);
  }
}
