import { fetch } from '../../utils/dataAccess';
import { reset } from '../../actions/auth/login';

export function logout(event) {
  return dispatch => {
    return fetch('/api/logout', {
      method: 'GET'
    }).then(retrieved => {
        reset();
        window.location.reload();
    });
  };
}

