/**
 * Created by hperkins on 10/10/2019.
 */

import { combineReducers } from 'redux';

export function enabled(state = false, action) {
  switch(action.type){
    case 'SCANNER_ENABLED':
      return action.enabled;
    default:
      return state;
  }
}

export default combineReducers({ enabled });
