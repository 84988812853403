import { ENTRYPOINT } from '../config/entrypoint';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get';
import has from 'lodash/has';
import mapValues from 'lodash/mapValues';

import store from './store.js';
import history from './history.js';

const MIME_TYPE = 'application/json';

export function fetch(id, options = {}) {
  const state = store.getState();
  let authToken = state.auth.login.token;
  let authId = state.auth.login.userId;

  if ('undefined' === typeof options.headers) options.headers = new Headers();
  if (null === options.headers.get('Accept'))
    options.headers.set('Accept', MIME_TYPE);

  if (
    'undefined' !== options.body &&
    !(options.body instanceof FormData) &&
    null === options.headers.get('Content-Type')
  )

  options.headers.set('Content-Type', MIME_TYPE);
  options.headers.set('x-api-user-id', authId);
  options.headers.set('x-api-user-token', authToken);

  return global.fetch(new URL(id, ENTRYPOINT), options).then(response => {
    if (response.ok) return response;

    if (response.status === 401) {
      history.push('/login');
      return null;
    }

    return response.json().then(json => {
      const error = json['hydra:description'] || response.statusText;
      if (!json.violations) throw Error(error);

      let errors = { _error: error };
      json.violations.map(
        violation => (errors[violation.propertyPath] = violation.message)
      );

      throw new SubmissionError(errors);
    });
  });
}

export function verifyAuthentication() {
  const state = store.getState();

  let expiration = state.auth.login.expiration*1000;
  let date = new Date();
  let timestamp = date.getTime();
  if (timestamp < expiration) return true;

  let remember = state.auth.login.remember;
  if (!remember) return false;

  // let authToken = state.auth.login.token;
  // let authId = state.auth.login.userId;

  global.fetch()
}

export function mercureSubscribe(url, topics) {
  topics.forEach(topic =>
    url.searchParams.append('topic', new URL(topic, ENTRYPOINT))
  );

  return new EventSource(url.toString());
}

export function normalize(data) {
  if (has(data, 'hydra:member')) {
    // Normalize items in collections
    data['hydra:member'] = data['hydra:member'].map(item => normalize(item));

    return data;
  }

  // Flatten nested documents
  return mapValues(data, value =>
    Array.isArray(value)
      ? value.map(v => get(v, '@id', v))
      : get(value, '@id', value)
  );
}

export function extractHubURL(response) {
  const linkHeader = response.headers.get('Link');
  if (!linkHeader) return null;

  const matches = linkHeader.match(
    /<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/
  );

  return matches && matches[1] ? new URL(matches[1], ENTRYPOINT) : null;
}
