import React, { Component } from "react";
import { connect } from "react-redux";
import { BarcodeScanner } from "dynamsoft-javascript-barcode";
import { disable, reset } from "../../actions/search/scanner";
import { query, setSearchText } from "../../actions/search/searchlist";

import "../../assets/styles/search/scanner.css";

class Scanner extends Component {

  scanner = null;
  vin = null;
  cameras = 0;
  currentCamera = 0;

  async componentDidMount() {
    this.confirmation = document.getElementById('scanner-confirmation')
    this.confirmation.style.display = 'none';
    this.vin = null;
    this.props.setSearchText('')

    try {
      if (this.scanner) {
        await this.scanner.show();
      } else {
        this.scanner = await BarcodeScanner.createInstance();

        let cameras = await this.scanner.getAllCameras();
        if(cameras.length) {
          this.cameras = cameras;
          await this.scanner.setCurrentCamera(this.cameras[this.currentCamera]);
        }

        document.getElementById('div-ui-container').appendChild(this.scanner.getUIElement());
        const closeButton = document.getElementsByClassName('dce-btn-close')[0];
        const ourCloseButton = document.getElementById('div-ui-close');
        const cameraCycle = document.getElementById('div-ui-camcycle');

        closeButton.replaceWith(ourCloseButton);
        if (this.cameras.length > 1) {
          ourCloseButton.insertAdjacentElement('afterend', cameraCycle);
          cameraCycle.style.display = 'block';
        }

        this.scanner.onUniqueRead = (txt, result) => {
          this.vin = txt
          document.getElementById('scanned-vin').innerHTML = txt;
          this.showConfirmation();
          this.scanner.hide();
        }

        await this.scanner.show();
      }
    } catch (ex) {
      alert("Error encountered while starting up the Barcode Scanner. Please try again later. If the issue persists please contact Bay Brokerage for support.");
    }
  }

  async cycleCamera() {
    if (this.cameras.length <= 1) return;
    this.currentCamera++;
    if (this.currentCamera > this.cameras.length) {
      this.currentCamera = 0;
    }

    await this.scanner.setCurrentCamera(this.cameras[this.currentCamera]);
  }

  hideConfirmation() {
    this.confirmation.style.display = 'none';
  }

  showConfirmation() {
    this.confirmation.style.display = 'flex';
  }

  async componentWillUnmount() {
    if (this.scanner) {
      await (this.scanner).destroyContext();
      this.scanner = null;
    }
    this.props.reset();
  }

  render() {

    return (
      <div className="barcode-scanner">
        <div id="div-ui-container">
          <div id="scanner-confirmation">
            <div id="scanned-vin"></div>
            <div id="vin-buttons">
              <div id="vin-button-confirm" onClick={() => {
                this.hideConfirmation()
                this.props.setSearchText(this.vin)
                this.props.disable()
              }}><span className="fa fa-check"></span></div>
              <div id="vin-button-reject" onClick={() => {
                this.hideConfirmation()
                this.scanner.show()
              }}><span className="fa fa-times"></span></div>
            </div>
          </div>
          <button type="button" id="div-ui-close" className="btn btn-default btn-circle" aria-label="Close" onClick={() => this.props.disable()}>
            <i className="fa fa-times"></i>
          </button>
          <button type="button" id="div-ui-camcycle" className="btn btn-default btn-circle" aria-label="Change Camera" onClick={() => this.cycleCamera()}>
            <i className="fa fa-refresh"></i>
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  companyId: state.company.list.companyId
});
const mapDispatchToProps = dispatch => ({
  setSearchText: text => dispatch(setSearchText(text)),
  searchListQuery: (company_id, text) => dispatch(query(company_id, text)),
  disable: () => dispatch(disable()),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Scanner);
