import { combineReducers } from 'redux';

export function error(state = null, action) {
  switch (action.type) {
    case 'LOGIN_AUTH_ERROR':
      return action.error;

    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case 'LOGIN_AUTH_LOADING':
      return action.loading;

    default:
      return state;
  }
}

export function success(state = null, action) {
  switch (action.type) {
    case 'LOGIN_AUTHENTICATED':
      return action.success;

    default:
      return state;
  }
}

export function token(token = null, action) {
  switch (action.type) {
    case 'LOGIN_TOKEN':
      return action.token;

    default:
      return token;
  }
}

export function expiration(expiration = null, action) {
  switch (action.type) {
    case 'LOGIN_EXPIRATION':
      return action.expiration;

    default:
      return expiration;
  }
}

export function userId(id = null, action) {
  switch (action.type) {
    case 'LOGIN_USER_ID':
      return action.id;

    default:
      return id;
  }
}

export function remember(remember = null, action) {
  switch (action.type) {
    case 'LOGIN_REMEMBER':
      return action.remember;

    default:
      return remember;
  }
}

export default combineReducers({ error, loading, success, token, expiration, userId, remember });
