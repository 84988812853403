import { createStore, combineReducers, applyMiddleware } from 'redux';
import {
  connectRouter,
  routerMiddleware
} from 'connected-react-router';

import thunk from 'redux-thunk';

import { reducer as form } from 'redux-form';

import history from './history.js';

import auth from '../reducers/auth/index.js';
import company from '../reducers/company/index.js';
import shipment from '../reducers/shipment/index.js';
import search from '../reducers/search/index.js';
import vehicle from '../reducers/vehicle/index.js';

const persistedState = localStorage.getItem('reduxState') ? JSON.parse(localStorage.getItem('reduxState')) : {};

const store = createStore(
  combineReducers({
    router: connectRouter(history),
    form,
    auth,
    company,
    search,
    shipment,
    vehicle
  }),
  persistedState,
  applyMiddleware(routerMiddleware(history), thunk)
);

store.subscribe(() => {
  localStorage.setItem('reduxState', JSON.stringify(store.getState()))
});

export default store;
