/**
 * Created by hperkins on 9/19/2019.
 */

import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

class PrivateRoute extends React.Component {
  render() {
    const Component = this.props.component;

    let authToken = this.props.token;
    let authId = this.props.userId;

    return (<Route render={(props) => ( (authToken !== null && authId !== null) ? <Component {...props} /> : <Redirect to='/login' />)} />)
  }
}

const mapStateToProps = state => {
  const { token, userId, expiration, remember } = state.auth.login;
  return { token, userId, expiration, remember };
};

const mapDispatchToProps = dispatch => ({ });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
